export interface PortalMortgageDocumentModel {
    mortgageDocumentId: string;
    name: string;
    portalName: string;
    description: string;
    category: string | null;
    categoryId: string | null;
    document: string | null;
    institution: string | null;
    applicableTo: number | null;
    statusReason: number | null;
    mortgageId: string | null;
    documentId: string | null;
    institutionId: string | null;
    currentBalance: number | 0;
    order: number | null;
    helpText: string | null;
    documentType: number | null;
    limit?: number | 0;
    termLeft?: string;
    termPeriod: number | null;
    categoryUniqueCode: string;
    accountNumber?: string | null;
    planOnClearingTheBalance?: number | null;
    recurringMonthlyValue: number | null;
    recurringYearlyValue: number | null;
    sellBeforePurchasing: boolean | null;
    primaryApplicantMetFaceToFace: boolean | null;
    secondaryApplicantMetFaceToFace: boolean | null;
    hasAny: number | null;
    loanType: number | null;
    mortgageBalanceOutstanding: number | null;
    otherInstitutionName: string;
    usedForDeposit: boolean | null;
    isJoint: boolean | null;
    categoryOrder: number | null;
    termRemaining: number | null;
    overdraftLimit: number | null;
    comments: string | null;
    houseHunterDocument: boolean | null;
    displayToCustomer: boolean | null;
    transactionSource: number | null;
}

export enum HasAny
{
    No = 755980001,
    Yes = 755980000
}

export enum PortalDocumentType {
    Document = 755980000,
    Category = 755980001,
    IDPal = 755980002,
    OpenBanking = 755980003
}

export enum ApplicableTo {
    Primary = 755980000,
    Secondary = 755980001,
    Shared = 755980002
}

export enum MortgageDocumentStatusCode {
    Active = 1,
    Inactive = 2,
    Uploaded = 755980001,
    NeedsAction = 755980002,
    Approved = 755980003,
    InProgress = 755980004
}

export enum LoanType
{
    Car = 755980000,
    Personal = 755980001,
    HomeImprovement = 755980002,
    Mortgage = 755980005,
    Other = 755980004
}

export enum TransactionSource
{
    OpenBanking = 680200000,
    UploadedStatements = 680200001
}