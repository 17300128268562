import axios from "axios";
import AnimateHeight from "react-animate-height";
import { Stack, Container, Row, Col, Modal, Form } from "react-bootstrap";
import { ApplicableTo, HasAny, MortgageDocumentStatusCode, PortalDocumentType, PortalMortgageDocumentModel } from "../../models/PortalMortgageDocumentModel";
import { IFileItem } from "../DocumentManager";
import { FileUpload } from "../FileUpload";
import * as Icons from "react-bootstrap";
import { UserContextComponent } from "../UserContextComponent";
import { Tooltip } from "../Tooltip";
import { Navigate } from "react-router-dom";
import { OpenBanking } from "../../pages/OpenBanking";
import { ChevronUp } from "react-bootstrap-icons";
import { UploadResponse } from "../../models/UploadResponse";
import { logger } from "../../helper/Logger";
import { AIPType } from "../../models/PortalAIPApplication";
import { FileUploadLegacy } from "../FileUploadLegacy";
import { TransactionComments } from "./TransactionComments";
import DraggableModal from "../DraggableModal";
import { Pill } from "./Pill";
import { IPillElementModel } from "./PillElement";
import { Finance } from "../Finance/Finance";
import { PortalContactModel } from "../../models/PortalContactModel";

interface IFinancePillProps {
    mortgageId: string | undefined;
    document: PortalMortgageDocumentModel;
    files: IFileItem[];
    contactId: string | null;
    helperTexts: any[];
    applicantNo: number;
    onDone(mortgageDocumentId: string, status: MortgageDocumentStatusCode): void;
    onUploadComplete?(url: string): void;
    setContact(contact: any): void;
    refreshParent(): any;
}

interface IFinancePillState {
    isCollapsed: boolean;
    isHelpTextCollapsed: boolean;
    files: IFileItem[];
    showPinDialog: boolean;
    customerComment: string;
    originalPin: string;
    pin: string;
    selectedFileName: string;
    idPalLoader: boolean;
    selectedFileUploadId: string;
    useOpenBanking: boolean;
    navigateTo: string | null;
    uploadcollapsed: boolean;
    showTransactionComments: boolean;
    modalpage: number;
    isSaving: boolean;
    isSavingForFiles: boolean;
    isDirty: boolean;
    validationError: boolean;
    duplicateAccountError: boolean;
}

export class FinancePill extends UserContextComponent<IFinancePillProps, IFinancePillState> {
    private documentType: PortalDocumentType | null;

    constructor(props: IFinancePillProps) {
        super(props);
        this.state = { 
            isCollapsed: !(this.props.document as any).autoOpen,
            files: this.props.files, 
            uploadcollapsed: this.props.files.length > 0, 
            showPinDialog: false, 
            customerComment: "", 
            originalPin: "", 
            pin: "", 
            selectedFileName: "", 
            idPalLoader: false, 
            selectedFileUploadId: "", 
            navigateTo: null, 
            useOpenBanking: true, 
            isHelpTextCollapsed: true,
            showTransactionComments: false,
            modalpage: 1,
            isSaving: false,
            isSavingForFiles: false,
            isDirty: false,
            validationError: false,
            duplicateAccountError: false,
        };
        this.downloadFile = this.downloadFile.bind(this);

        this.uploadComplete = this.uploadComplete.bind(this);
        this.deleteComplete = this.deleteComplete.bind(this);
        this.confirmUpload = this.confirmUpload.bind(this);
        this.getPillClass = this.getPillClass.bind(this);


        this.documentType = this.props.document.documentType;

        this.closePinDialog = this.closePinDialog.bind(this);
        this.showCommentAndPinDialog = this.showCommentAndPinDialog.bind(this);
        this.saveCommentAndPin = this.saveCommentAndPin.bind(this);

        this.collapsePill = this.collapsePill.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.createDocument = this.createDocument.bind(this);
        this.updateDocument = this.updateDocument.bind(this);
        this.deleteDocumentEx = this.deleteDocumentEx.bind(this);
        this.goToFiles = this.goToFiles.bind(this);
        this.isDirty = this.isDirty.bind(this);
    }

    componentDidMount(): void {
        let context = this.getContext();
        (this.props.document as any).autoOpen = false;
    }

    closePinDialog() {
        this.setState({ showPinDialog: false });
    }

    showCommentAndPinDialog(filename: string, uploadId: string, custComment: string, pin: string) {
        this.setState({ selectedFileName: filename, customerComment: custComment, originalPin: pin, pin: pin, showPinDialog: true, selectedFileUploadId: uploadId });
    }

    saveCommentAndPin() {
        // Send in a null for the pin if not updated. Sending in a pin will trigger the automatic file unlock which clears the Comments on success, and we only want that when a pin changes.
        axios.post('/SP/UpdateCommentPin', null, { params: { mortgageId: this.props.mortgageId, filename: this.state.selectedFileName, uploadId: this.state.selectedFileUploadId, custComment: this.state.customerComment, pin: (this.state.pin == this.state.originalPin ? null : this.state.pin), mortgageDocumentId: this.props.document.mortgageDocumentId } })
            .then(response => {
                this.setState({ showPinDialog: false });
                let items: IFileItem[] = this.state.files;
                let item = items.find(x => x.name === this.state.selectedFileName);
                if (item) {
                    item.customerComment = this.state.customerComment;
                    item.pin = this.state.pin;
                    item.status = "Awaiting advisor review";
                    if (this.state.pin != this.state.originalPin) {
                        item.comment = "";
                    }
                }
            })
            .catch((e) => {
                logger.log(e);
            });
    }

    getPillClass() {
        if (this.needsAction() && this.props.document.statusReason !== MortgageDocumentStatusCode.Approved)
            return "needsattention";
        if (this.props.document.statusReason === MortgageDocumentStatusCode.Active)
            return this.state.files?.length > 0 ? "inprogress" : "notstarted";
        if (this.props.document.statusReason === MortgageDocumentStatusCode.Uploaded)
            return "done";
        if (this.props.document.statusReason === MortgageDocumentStatusCode.InProgress)
            return "inprogress"
        if (this.props.document.statusReason === MortgageDocumentStatusCode.Approved)
            return "approved"

        return "notstarted";
    }

    needsAction() {
        var filesWithComments = this.props.files.filter(f => (
                (f.comment??"")!=="" || 
                (f.transactionComments??[]).filter(t => !t.customerComment).length>0
            ));
        if (this.props.document.comments ||
            filesWithComments.length > 0 ||
            this.props.document.statusReason === MortgageDocumentStatusCode.NeedsAction)
            return true;
        return false;
    }

    private uploadComplete(data: UploadResponse, category: string, mortgageDocumentId: string, contactId: string, uploadId: any) {
        if (this.props.onUploadComplete)
            this.props.onUploadComplete(data.url);
        let items: IFileItem[] = this.state.files;
        let mortgageDocument = items.find(x => x.mortgageDocumentId === mortgageDocumentId)?.mortgageDocument;
        let item: IFileItem = {
            id: items.length + 100000,
            category: category,
            mortgageDocument: mortgageDocument,
            mortgageDocumentId: mortgageDocumentId,
            name: data.url.split('/').pop()!,
            status: (data.accepted ? "For advisor review" : "Rejected"),
            comment: data.message || "",
            customerComment: "",
            pin: "",
            uploadId: uploadId
        };
        items.push(item);
        this.setState({ files: items, isDirty: true });
    }

    private deleteComplete(data: any, mortgageDocumentId: string) {
        let items: IFileItem[] = this.state.files;
        let itemIndex = items.findIndex(x => x.mortgageDocumentId === mortgageDocumentId && x.name === data.split('/').pop());
        if (itemIndex > -1)
            items.splice(itemIndex, 1);
        this.setState({ files: items });
    }

    confirmUpload(mortgageDocumentId: string): void {
        const context = this.getContext();
        axios.post('/MortgageDocuments/MarkMortgageDocumentAsUploaded', null, { params: { mortgageDocumentId: mortgageDocumentId, mortgageId: context.mortgage?.mortgageId } })
            .then(response => {
                this.props.document.statusReason = MortgageDocumentStatusCode.Uploaded;
                context.updateDoc(this.props.document);
                this.setState({isDirty: true});
                this.props.onDone(mortgageDocumentId, MortgageDocumentStatusCode.Uploaded);
            });
    }

    downloadFile(mortgageId: string | undefined, fileName: string, uploadId: string): void {
        if (mortgageId) {
            axios({
                url: `/SP/DownloadFile?mortgageId=${mortgageId}&fileName=${encodeURIComponent(fileName)}&uploadId=${uploadId}`,
                method: "GET",
                responseType: "blob"
            }).then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                if (response.headers["content-type"] === "application/pdf" && fileName.indexOf(".pdf") === -1) {
                    fileName = fileName + ".pdf";
                }
                link.setAttribute("download", fileName);
                document.body.appendChild(link);
                link.click();
                if (link.parentNode != null)
                    link.parentNode.removeChild(link);
            });
        }
    }

    getStatusColour() {
        var currentStatus = this.props.document.statusReason ?? 0;
        if (currentStatus === MortgageDocumentStatusCode.Approved){
            return 'var(--chertreuse)';
        } else if (this.needsAction()) {
            return 'var(--limestone)';
        } else if (currentStatus === MortgageDocumentStatusCode.Uploaded) {
            return 'var(--chartreuse)';
        } else if (currentStatus === MortgageDocumentStatusCode.InProgress) {
            return 'var(--sage)';
        }

        return undefined;
    }

    getStatusText() {
        var currentStatus = this.props.document.statusReason ?? 0;
        if (currentStatus === MortgageDocumentStatusCode.Approved){
            return "Statements Complete";
        } else if (this.needsAction()) {
            return "Needs Action";
        } else if (currentStatus === MortgageDocumentStatusCode.Uploaded) {
            return "Statements Uploaded";
        } else if (currentStatus === MortgageDocumentStatusCode.InProgress) {
            return "Statements Needed";
        }
        return "Waiting";
    }

    collapsePill() {
        if (this.state.files.length > 0 && this.props.document.statusReason !== MortgageDocumentStatusCode.Approved) {
            this.confirmUpload(this.props.document.mortgageDocumentId!);
        }
        this.setState({ isCollapsed: true, isSaving: false, isSavingForFiles: false, modalpage: 1 });
    }

    handleClose() {
        if (this.state.isDirty) {
            this.setState({isSaving: true});
            if ((this.props.document as any).new) {
                this.createDocument(false);
            } else {
                this.updateDocument(false);
            }
        } else {
            if((this.props.document as any).new) {
                this.getContext().deleteDoc(this.props.document);
            }
            this.collapsePill();
        }
    }

    createDocument = (goToFiles: boolean) => {
        let context = this.getContext();
        let doc = this.props.document;

        if (doc.accountNumber && this.accountNumberAlreadyExists(doc)) {
            this.setState({duplicateAccountError: true, isSaving: false, isSavingForFiles: false});
            return;
        }
        if (this.state.duplicateAccountError) {
            this.setState({duplicateAccountError: false});
        }

        let institutionName = doc.institutionId != null ? context.institutions.find(i => i.value === doc.institutionId)?.text : doc.otherInstitutionName;
        var newMortgageDoc = {
            documentId: doc.documentId,
            institutionId: doc.institutionId,
            mortgageId: doc.mortgageId,
            applicableTo: this.props.applicantNo === 1 ? ApplicableTo.Primary : ApplicableTo.Secondary,
            name: ((institutionName ?? '') + ' ' + (doc.accountNumber ?? '')).trim(),
            category: doc.category,
            categoryId: doc.categoryId,
            document: doc.document,
            institution: institutionName,
            currentBalance: doc.currentBalance ?? 0,
            documentType: doc.documentType,
            limit: doc.limit ?? 0,
            termLeft: doc.termLeft ?? 0,
            termPeriod: doc.termPeriod ?? null,
            termRemaining: doc.termRemaining ?? null,
            categoryUniqueCode: doc.categoryUniqueCode,
            accountNumber: doc.accountNumber ?? "",
            planOnClearingTheBalance: doc.planOnClearingTheBalance,
            recurringYearlyValue: doc.recurringYearlyValue,
            recurringMonthlyValue: doc.recurringMonthlyValue,
            contactId: this.props.contactId,
            hasAny: HasAny.Yes,
            statusReason: MortgageDocumentStatusCode.Active,
            isJoint: doc.isJoint,
            otherInstitutionName: doc.otherInstitutionName,
            key: this.props.document.mortgageDocumentId
        };

        axios.post('/MortgageDocuments/CreateDocument', newMortgageDoc)
            .then(response => {
                let resp = response.data;
                var contact = this.getContact();
                contact.assetsInfoPercentage = resp.assetsPercentage;
                contact.bankingInfoPercentage = resp.bankingPercentage;
                contact.percentageOfCompletion = (
                        (contact.personalInfoPercentage??0) +
                        (contact.residentialInfoPercentage??0) +
                        (contact.employmentInfoPercentage??0) +
                        (contact.bankingInfoPercentage??0) +
                        (contact.assetsInfoPercentage??0)
                        ) / 5;

                // BANDAID - THIS IS TO KEEP THE APPLICANT MENU IN SYNC AS IT USES THE CONTEXT
                let context = this.getContext();
                if (this.props.applicantNo === 1) {
                    context.mortgage!.primaryApplicant = contact;
                } else {
                    context.mortgage!.secondaryApplicant = contact;
                }
                context.updateDoc(newMortgageDoc);
                context.updateId(doc.mortgageDocumentId, resp.mortgageDocumentId);

                this.props.setContact(contact);
                if (goToFiles) {
                    this.setState({ modalpage:2, isSaving: false, isSavingForFiles: false });
                } else {
                    this.collapsePill();
                }
            })
            .catch(() => {
                this.setState({ isSaving: false, isSavingForFiles: false });
            }
            );
    }

    
    accountNumberAlreadyExists(doc:PortalMortgageDocumentModel) {
        var context = this.getContext();
        if (context.mortgageDocumentList != null) {
            let categories = ['STATEMENT','SAVING'];

            let docs = context.mortgageDocumentList.filter(
                c => 
                    categories.includes(c.categoryUniqueCode??'') && 
                    c.accountNumber === doc.accountNumber &&
                    c.mortgageDocumentId !== doc.mortgageDocumentId
            );

            return docs.length > 0;
        }
        return [];
    }

    updateDocument = (goToFiles: boolean) => {
        let doc = this.props.document;
        if (doc.accountNumber && this.accountNumberAlreadyExists(doc)) {
            this.setState({duplicateAccountError: true, isSaving: false, isSavingForFiles: false});
            return;
        }
        if (this.state.duplicateAccountError) {
            this.setState({duplicateAccountError: false});
        }

        doc.currentBalance = (!doc.currentBalance || doc.currentBalance?.toString().trim() === "") ? 0 : doc.currentBalance;
        doc.applicableTo = this.props.applicantNo === 1 ? ApplicableTo.Primary : ApplicableTo.Secondary;
        let context = this.getContext();
        doc.institution = (doc.institutionId != null ? context.institutions.find(i => i.value === doc.institutionId)?.text : doc.otherInstitutionName) ?? '';
        doc.name = ((doc.institution ?? '') + ' ' + (doc.accountNumber ?? '')).trim();

        axios.post('/MortgageDocuments/UpdateDocument', doc)
            .then(response => {
                var contact = this.getContact();

                // There's a rogue order of events someplace blowing up the asset calculation.
                // To protect against this we may get a -1 back. Handle that situation
                if (response.data != -1) {
                    var percentages = response.data;
                    contact.assetsInfoPercentage = percentages.assetsAndLoans;
                    contact.bankingInfoPercentage = percentages.banking; 
                    contact.percentageOfCompletion = (
                            (contact.personalInfoPercentage??0) +
                            (contact.residentialInfoPercentage??0) +
                            (contact.employmentInfoPercentage??0) +
                            (contact.assetsInfoPercentage??0) + 
                            (contact.bankingInfoPercentage??0)
                            ) / 5;
                }
                
                // BANDAID - THIS IS TO KEEP THE APPLICANT MENU IN SYNC AS IT USES THE CONTEXT
                let context = this.getContext();
                if (this.props.applicantNo === 1) {
                    context.mortgage!.primaryApplicant = contact;
                } else {
                    context.mortgage!.secondaryApplicant = contact;
                }
                this.props.setContact(contact);
                context.updateDoc(doc);

                if (goToFiles) {
                    this.setState({ modalpage:2, isSaving: false, isSavingForFiles: false });
                } else {
                    this.collapsePill();
                }
            })
            .catch(() => {
                this.setState({ isSaving: false, isSavingForFiles: false });
            });
    }
    
    
    deleteDocumentEx() {
        if (!this.state.isSaving) {
            this.setState({isSaving: true});
            this.deleteDocument();
        }
    }

    deleteDocument = () => {
        let doc = this.props.document as any;
        if (doc.new || doc.mortgageDocumentId.indexOf(".") > -1) {
            logger.log("cancelDocument");
            //this.cancelDocument(e, doc);
            this.getContext().deleteDoc(doc);
            this.setState({isCollapsed: true, isSaving: false});
        }
        else {
            logger.log("DeleteDocument");
            axios.post('/MortgageDocuments/DeleteDocument', null, { params: { mortgageDocumentId: doc.mortgageDocumentId } })
                .then(response => {
                    this.getContext().deleteDoc(doc);
                    this.setState({isCollapsed: true, isSaving: false});
                })
                .catch(() => {
                    this.setState({isSaving: false});
                });
        }
    }

    getContact() {
        let context = this.getContext();
        var contact:PortalContactModel;
        if (this.props.applicantNo === 1) {
            contact = context.mortgage!.primaryApplicant!;
        } else {
            contact = context.mortgage!.secondaryApplicant!;
        }
        return contact;
    }

    goToFiles() {
        let doc = this.props.document as any;
        if (!this.props.document.institutionId) 
        {
            this.setState({ validationError: true });
        }
        else {
            if (doc.new || doc.mortgageDocumentId.indexOf(".") > -1) {
                this.setState({ isSavingForFiles: true });
                this.createDocument(true)
            } else {
                if (this.state.isDirty) {
                    this.setState({ isSavingForFiles: true });
                    this.updateDocument(true);
                } else {
                    this.setState({ modalpage: 2 });
                }
            }
        }
    }

    isDirty(value: boolean) {
        const context = this.getContext();
        let doc = this.props.document;

        let institutionName = doc.institutionId != null ? context.institutions.find(i => i.value === doc.institutionId)?.text : doc.otherInstitutionName;
        this.props.document.institution = institutionName ?? '';

        this.setState({isDirty: value});
    }

    render() {
        const context = this.getContext();
        var pillStatus = this.getPillClass();
        var comments = this.state.files.filter(f => f.comment || (f.transactionComments && f.transactionComments.length > 0));
        const {institution, accountNumber, isJoint} = (this.props.document);

        if (this.state.navigateTo) {
            return <Navigate to={this.state.navigateTo} />;
        }

        // This section displays on a different place depending on whether you are on mobile or desktop
        var commentsAndFiles = <>
            {!this.props.document.comments && comments.length === 0 && this.state.files.length === 1 &&
                <>
                    <div className="pb-1"><span><strong>{this.state.files.length} File</strong> added</span></div>
                </>
            }
            {!this.props.document.comments && comments.length === 0 && this.state.files.length !== 1 && 
                <>
                    <div className="pb-1"><span><strong>{this.state.files.length} Files</strong> added</span></div>
                </>
            }
            {(this.props.document.comments || comments.length > 0) &&
                <div className="box box-travertine">
                    <i className="fas fa-triangle-exclamation pill-big needsattention"></i>
                {this.props.document.comments &&
                    <div className="pt-2">
                        <Row>
                            <Col>
                                <h6 className="mb-0"><strong>For review</strong></h6>
                                <h6 className="render-linebreaks">{this.props.document.comments}</h6>
                            </Col>
                        </Row>
                    </div>
                }
                
                {comments.length > 0 && comments.map((item, idx) => {
                    return <div key={"comment-"+item.id} className="pt-2">
                        <Row>
                            <Col>
                                <h6 className="mb-0 filename"><strong>{item.name}</strong></h6>
                                {item.comment &&
                                    <h6 className="render-linebreaks">{item.comment} <span className="pointer doddltext" style={{marginLeft: 4}} onClick={e => this.showCommentAndPinDialog(item.name, item.uploadId, item.customerComment, item.pin)}><u><i className="fa-solid fa-reply"></i> reply</u></span></h6>
                                }
                                <TransactionComments item={item} mortgageId={this.props.mortgageId!} />
                            </Col>
                        </Row>
                    </div>;
                    }
                )}
                </div>
            }

            {this.state.files.map((item, idx) => {
                return <div key={"file-" + item.id} className={`document-file ${item.status === 'Approved' ? 'complete' : ''}`}>
                    <Row>
                        <Col xs="1">
                            {item.status === 'Approved' &&
                                <Tooltip tooltip={{ "fieldname": "", "title": "", "content": "doddl will review your documents and get back to you soon!" }}>
                                    <i className="fa fa-circle-check file-icon" style={{color: 'var(--teal)'}}></i>
                                </Tooltip>
                            }
                            {item.status === 'Rejected' &&
                                <Tooltip tooltip={{ "fieldname": "", "title": "", "content": "There is an issue with this document" }}>
                                    <i className="fas fa-triangle-exclamation pill-big pill-icon needsattention"></i>
                                </Tooltip>
                            }
                            {item.status !== 'Approved' && item.status !== 'Rejected' &&
                                <i className="far fa-clock file-icon"></i>
                            }
                        </Col>
                        <Col xs="8">
                            <h6 className="filename">
                                <strong><span style={{ paddingLeft: 10, cursor: "pointer" }} onClick={e => { this.downloadFile(this.props.mortgageId, item.name, item.uploadId); }}>{item.name}</span></strong>
                            </h6>
                        </Col>
                        <Col xs="3" className="text-end">
                            <Tooltip tooltip={{ "fieldname": "", "title": "", "content": "Download file" }}>
                                <i className="fas fa-cloud-download pointer pe-2" onClick={e => { this.downloadFile(this.props.mortgageId, item.name, item.uploadId); }}></i>
                            </Tooltip>
                            <Tooltip tooltip={{ "fieldname": "", "title": "", "content": "Add a comment or let us know if a pin is required to unlock this file" }}>
                                <i className="fas fa-pencil pointer pe-2" onClick={e => this.showCommentAndPinDialog(item.name, item.uploadId, item.customerComment, item.pin)}></i>
                            </Tooltip>
                            {/* <Tooltip tooltip={{ "fieldname": "", "title": "", "content": "Delete this file" }}>
                                <i className="fa fa-trash pointer pe-2" onClick={e => this.showCommentAndPinDialog(item.name, item.uploadId, item.customerComment, item.pin)}></i>
                            </Tooltip> */}

                        </Col>
                    </Row>
                </div>
            }                                
            )}

        </>;
        var institutionLookupItem = context.institutions.find(x => x.value == this.props.document.institutionId);
        var bg = institutionLookupItem?.item.logoTile ?? 'https://doddl.ie/wp-content/uploads/bank-tile.png';

        return (
            <Container className={`finance-pill-container w-360  ${' ' ?? pillStatus}`} >
                <div title={this.props.document.name}
                    onClick={() => this.setState({ isCollapsed: !this.state.isCollapsed })}
                    className={`finance-pill w-360 nowrap`}>
                    <Row>
                        <Col xs={2} className="pt-col me-3-mobile">
                            <div className={"finance-pill-logo"} style={{backgroundImage: `url('${bg}')`}} />
                        </Col>
                        <Col xs={7} className="text-start pt-col">
                            <div>
                                <strong>{institution??''}</strong>
                                <span className="ms-2 me-2">|</span>
                                {accountNumber??''}
                                <span className="ms-2 me-2">|</span>
                                <i className="fa fa-user" />
                                {isJoint && <i className="fa fa-user ps-1" /> }
                            </div>
                        </Col>
                        <Col xs={3} className="pt-col">
                            <div style={{marginTop: -4}}>
                                {this.props.document.statusReason === MortgageDocumentStatusCode.Approved && !this.needsAction() &&
                                <>
                                    <Row>
                                        <Tooltip tooltip={{ "fieldname": "", "title": "", "content": "Your documents have been reviewed and approved!" }}>
                                            <i className={`fas fa-circle-check pill-big`}></i>
                                        </Tooltip>
                                    </Row>
                                    <Row>
                                        <p className="tiny impact pt-1">COMPLETE</p>
                                    </Row>
                                </>
                                }

                                {this.props.document.statusReason === MortgageDocumentStatusCode.Uploaded && !this.needsAction() &&
                                <>
                                    <Row>
                                        <Tooltip tooltip={{ "fieldname": "", "title": "", "content": "doddl will review your documents and get back to you soon!" }}>
                                            <i className="far fa-clock pill-big inprogress"></i>
                                        </Tooltip>
                                    </Row>
                                    <Row>
                                        <p className="tiny pt-1">CHECKING</p>
                                    </Row>
                                </>
                                }
                                {this.needsAction() &&
                                <>
                                    <Row>
                                        <Tooltip tooltip={{ "fieldname": "", "title": "", "content": "There are some issues that need to be addressed on your documents" }}>
                                            <i className="fas fa-triangle-exclamation pill-big needsattention"></i>
                                        </Tooltip>
                                    </Row>
                                    <Row>
                                        <p className="tiny pt-1">ATTENTION</p>
                                    </Row>
                                </>
                                }
                            </div>
                        </Col>
                    </Row>
                </div>
                <Modal className="modal-fullscreen-xs" size="xl" show={!this.state.isCollapsed} onHide={this.collapsePill} centered>
                    <Modal.Header>
                    {this.state.modalpage === 1 &&
                        <Stack direction="horizontal" gap={2} className="pill-buttons-right pe-3 pt-3">
                                {this.state.isSavingForFiles &&
                                    <button className='btn btn-light big'><Icons.Spinner size="sm" animation="border" role="status" /></button>}
                            {(!this.state.isSavingForFiles && context.mortgage?.approvalType === AIPType.HouseHunter && this.props.document.categoryUniqueCode === "SAVING") ? "" :
                            <button className="btn btn-secondary big m nowrap" onClick={this.goToFiles}>{'Upload Statements >'}</button>
                            }
                            {this.state.isSaving ?
                            <button className='btn btn-light big'><Icons.Spinner size="sm" animation="border" role="status" /></button>
                            :
                            <button className='btn btn-light big' onClick={this.handleClose}>Close</button>
                            }
                        </Stack>
                    }
                    </Modal.Header>
                    {this.state.modalpage === 1 && <> 
                        <Modal.Body className="px-4 py-4">
                        <Row>
                            <Col xs={12} className="pe-4 minheight200">
                                    <h1 className="pb-3 minheight64 desktop-only">{institution}</h1>
                                    {this.state.validationError &&
                                        <>
                                            <p className="needsattention desktop-only">Please select the institution</p>
                                            <p className="needsattention mobile-only mt-5">Please select the institution</p>
                                        </>
                                    }
                                    {this.state.duplicateAccountError &&
                                        <>
                                            <p className="needsattention desktop-only">Account number already added</p>
                                            <p className="needsattention mobile-only mt-5">Account number already added</p>
                                        </>
                                    }
                                <Row className="xs-margin-top-3">
                                    <Col xs={11}>
                                        <Finance 
                                            className='mt-3'
                                            item={this.props.document} 
                                            institutionList={context.institutions} 
                                            helperTexts={this.props.helperTexts} 
                                            checkAccountNumber={undefined}
                                            isDirty={this.isDirty}
                                            />
                                    </Col>
                                    <Col xs={1}>
                                        <div style={{ cursor: "pointer", top: "40%", position: "relative", fontSize: 25, color: "rgb(153,153,153)" }} className="far fa-trash-can" onClick={e => this.deleteDocumentEx()}>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        </Modal.Body>
                    </>
                    }
                    {this.state.modalpage === 2 && <>
                    <Modal.Body className="px-4 py-4">
                        <Row>
                            <Col xs={12} md={8} className="pe-4 document-upload minheight200">
                                <h1 className="pb-3">{institution}</h1>
                                <h4 style={this.props.document.statusReason === MortgageDocumentStatusCode.Approved ? { paddingBottom: 10 } : { paddingBottom: 10 }} dangerouslySetInnerHTML={{ __html: this.props.document.helpText ?? "" }}></h4>
                                {(this.needsAction() && (this.props.document.comments === "" || this.props.document.comments === null)) &&
                                    <br />
                                }
                                <div className="mobile-only">
                                    {commentsAndFiles}
                                </div>
                                <div style={{paddingTop: 10}} />
                                {this.props.document.statusReason !== MortgageDocumentStatusCode.Approved &&
                                    <>
                                        {context.mortgage?.useLegacyUploader 
                                        
                                            ?
                                            <FileUploadLegacy
                                                mortgageId={context.mortgage?.mortgageId}
                                                contactId={this.props.contactId}
                                                category={this.props.document.category}
                                                mortgageDocumentId={this.props.document.mortgageDocumentId}
                                                onUploadComplete={(d: UploadResponse, uploadId: any) => { this.uploadComplete(d, this.props.document.category!, this.props.document.mortgageDocumentId!, this.props.contactId!, uploadId); }}
                                                onDeleteComplete={(d: any) => { this.deleteComplete(d, this.props.document.mortgageDocumentId!); }} />

                                            :
                                            <FileUpload
                                                mortgageId={context.mortgage?.mortgageId}
                                                contactId={this.props.contactId}
                                                category={this.props.document.category}
                                                mortgageDocumentId={this.props.document.mortgageDocumentId}
                                                onUploadComplete={(d: UploadResponse, uploadId: any) => { this.uploadComplete(d, this.props.document.category!, this.props.document.mortgageDocumentId!, this.props.contactId!, uploadId); }}
                                                onDeleteComplete={(d: any) => { this.deleteComplete(d, this.props.document.mortgageDocumentId!); }} />
                                        }
                                    </>
                                }
                            </Col>
                            <Col xs={4}>
                                <Stack direction="horizontal" gap={2} className="pill-buttons-right">
                                    {this.state.isSaving ?
                                    <button className='btn btn-light big'><Icons.Spinner size="sm" animation="border" role="status" /></button>
                                    :
                                    <button className='btn btn-light big' onClick={this.collapsePill}>Close</button>
                                    }
                                </Stack>

                                <div className="desktop-only">
                                    {commentsAndFiles}
                                </div>
                            </Col>
                        </Row>

                    </Modal.Body>
                    <DraggableModal 
                        show={this.state.showPinDialog} 
                        onHide={() => { this.closePinDialog(); }} 
                        title={""}
                        body={
                            <Form>
                                <Form.Group className="mb-3">
                                    <Form.Label>Comment</Form.Label>
                                    <Form.Control onMouseDown={(e) => {e.stopPropagation()}} disabled={this.props.document.statusReason === MortgageDocumentStatusCode.Approved && !this.needsAction()} as="textarea" rows={3} defaultValue={this.state.customerComment} onChange={e => this.setState({ customerComment: e.target.value })} autoFocus />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Document Password (if applicable)</Form.Label>
                                    <Form.Control onMouseDown={(e) => {e.stopPropagation()}} disabled={this.props.document.statusReason === MortgageDocumentStatusCode.Approved} type="password" defaultValue={this.state.pin} onChange={e => this.setState({ pin: e.target.value })} />
                                </Form.Group>
                            </Form>
                        }
                        footer={
                            <>
                                    <button className="btn btn-light" onClick={this.closePinDialog}>
                                        Close
                                    </button>
                                    {this.props.document.statusReason !== MortgageDocumentStatusCode.Approved &&
                                    <button className="btn btn-standard" onClick={this.saveCommentAndPin}>
                                        Save
                                    </button>
                                    }
                                </>
                        }
                    />
                    </>
                    }
                </Modal>
            </Container>
        );
    }
}