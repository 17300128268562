import React from "react";
import { Row, Col, Container, Card, Stack, Accordion } from "react-bootstrap";
import { ApplicableTo, MortgageDocumentStatusCode, PortalMortgageDocumentModel } from "../models/PortalMortgageDocumentModel";
import { DocumentPill } from "./Pills/DocumentPill";
import { ProgressTile, ProgressTileSize } from "./ProgressTile";
import UserContextState, { UserContext } from "./UserContext";
import { PortalCategoryModel } from "../models/PortalCategoryModel";
import { UserContextComponent } from "./UserContextComponent";
import * as Icons from "react-bootstrap";
import { AIPType } from "../models/PortalAIPApplication";
import { PortalTransactionCommentsModel } from "../models/PortalTransactionCommentsModel";
//import { PortalTransactionCommentsModel } from "../models/PortalTransactionCommentsModel";

export interface IFileItem {
    id: number;
    name: string;
    category: string;
    mortgageDocument: PortalMortgageDocumentModel | undefined;
    mortgageDocumentId: string;
    comment?: string;
    transactionComments?: PortalTransactionCommentsModel[] | null;
    status: string;
    customerComment: string;
    pin: string;
    uploadId: string;
}

interface IDocumentManagerProps {
    items: IFileItem[];
    documents: PortalMortgageDocumentModel[];
    applicants?: any[];
    mortgageId: string | undefined;
    categories: PortalCategoryModel[];
}

interface IDocumentManagerState {
    currentCategory: string | null;
    currentItem: IFileItem | null;
    refreshItem: any;
    primaryFilter: string | null;
    secondaryFilter: string | null;
    sharedFilter: string | null;
}

export class DocumentManager extends UserContextComponent<IDocumentManagerProps, IDocumentManagerState> {
    static contextType = UserContext;

    groupBy(arr: any[], key: string) {
        const initialValue = {};
        return arr.reduce((acc, cval) => {
            const myAttribute = cval[key];
            acc[myAttribute] = [...(acc[myAttribute] || []), cval]
            return acc;
        }, initialValue);
    };

    constructor(props: IDocumentManagerProps) {
        super(props);
        this.onDocumentDone = this.onDocumentDone.bind(this);

        for (let i = 0; i < props.items.length; i++) {
            props.items[i].mortgageDocument = props.documents.find(x => x.mortgageDocumentId === props.items[i].mortgageDocumentId);
        }

        this.state = {
            currentCategory: null,
            currentItem: null,
            refreshItem: null,
            primaryFilter: null,
            secondaryFilter: null,
            sharedFilter: null
        };
    }

    static calculatePercent(context: UserContextState, docs: PortalMortgageDocumentModel[]) {
        var doneDocs = docs.filter(i => i.statusReason === MortgageDocumentStatusCode.Uploaded || i.statusReason === MortgageDocumentStatusCode.Approved);
        var allDocs = docs.length;
        if (context.mortgage!.approvalType === AIPType.HouseHunter) {
            doneDocs = doneDocs.filter(i => i.houseHunterDocument === true);
            allDocs = docs.filter(i=> i.houseHunterDocument === true).length;
        }
        var completedDocs = doneDocs.length;
        return Math.round(completedDocs / allDocs * 100);
    }
    public calcPercent(docs: PortalMortgageDocumentModel[]) {
        return DocumentManager.calculatePercent(this.getContext(), docs);
    }

    onDocumentDone(mortgageDocumentId: string, status: MortgageDocumentStatusCode) {
        var doc: PortalMortgageDocumentModel | undefined = this.props.documents.find(i => i.mortgageDocumentId === mortgageDocumentId)
        if (doc) {
            let context = this.getContext();
            doc.statusReason = status;
            context.mortgage!.documentsPercentComplete = this.calcPercent(this.props.documents);
            this.setState({ refreshItem: doc });
        }
    }

    onClickCategory(applicant: ApplicableTo, category: string) {
        if (applicant === ApplicableTo.Primary)
            this.setState({ primaryFilter: (category === this.state.primaryFilter ? null : category) });
        if (applicant === ApplicableTo.Secondary)
            this.setState({ secondaryFilter: (category === this.state.secondaryFilter ? null : category) });
        if (applicant === ApplicableTo.Shared)
            this.setState({ sharedFilter: (category === this.state.sharedFilter ? null : category) });
    }

    getCardClass(applicant: ApplicableTo, category: string) {
        let context = this.getContext();
        let secondaryApplicantExist = this.props.applicants !== null && this.props.applicants !== undefined && this.props.applicants.length > 1 && this.props.applicants[1] !== null;
        var docs: PortalMortgageDocumentModel[];
        let allItems = this.props.items;
        var files: IFileItem[];
        var applicants: ApplicableTo[]=[applicant];

        if (applicant === ApplicableTo.Primary && !secondaryApplicantExist) {
            applicants.push(ApplicableTo.Shared);
        }
        docs = this.props.documents.filter(i => applicants.includes(i.applicableTo as ApplicableTo) && i.category === category);
        files = allItems.filter(i => i.mortgageDocument !== undefined && applicants.includes(i.mortgageDocument.applicableTo as ApplicableTo) && i.mortgageDocument.category === category);
        
        var extraClass = '';
        if (context.mortgage?.approvalType === AIPType.HouseHunter) {
            if(docs.filter(a => a.houseHunterDocument).length === 0) {
                extraClass = 'greyedcategory ';
            } else {
                extraClass = 'highlightcategory ';
            }
        }

        var filesWithComments = files.filter(f => (
                (f.comment??"")!=="" || 
                (f.transactionComments??[]).filter(t => !t.customerComment).length>0
            ));
        if (docs.filter(d => d.statusReason === MortgageDocumentStatusCode.NeedsAction).length > 0 
            || filesWithComments.length > 0) {
            return "needsattention " + extraClass;
        }
        return extraClass;
    }

    getCardHeaderClass(applicant: ApplicableTo, category: string) {
        let context = this.getContext();
        let secondaryApplicantExist = this.props.applicants !== null && this.props.applicants !== undefined && this.props.applicants.length > 1 && this.props.applicants[1] !== null;
        var docs: PortalMortgageDocumentModel[];
        let allItems = this.props.items;
        var files: IFileItem[];
        var applicants: ApplicableTo[]=[applicant];

        if (applicant === ApplicableTo.Primary && !secondaryApplicantExist) {
            applicants.push(ApplicableTo.Shared);
        }
        docs = this.props.documents.filter(i => applicants.includes(i.applicableTo as ApplicableTo) && i.category === category);
        files = allItems.filter(i => i.mortgageDocument !== undefined && applicants.includes(i.mortgageDocument.applicableTo as ApplicableTo) && i.mortgageDocument.category === category);
        
        var extraClass = '';
        if (context.mortgage?.approvalType === AIPType.HouseHunter) {
            if(docs.filter(a => a.houseHunterDocument).length === 0) {
                extraClass = 'greyedcategory ';
            } else {
                extraClass = '';
            }
        }

        var filesWithComments = files.filter(f => (
                (f.comment??"")!=="" || 
                (f.transactionComments??[]).filter(t => !t.customerComment).length>0
            ));
        if (docs.filter(d => d.statusReason === MortgageDocumentStatusCode.NeedsAction || d.comments).length > 0 
            || filesWithComments.length > 0) {
            return "needsattention " + extraClass;
        }
        return extraClass;
    }

    getHouseHunterClass(applicant: ApplicableTo, category: string) {
        let context = this.getContext();
        let secondaryApplicantExist = this.props.applicants !== null && this.props.applicants !== undefined && this.props.applicants.length > 1 && this.props.applicants[1] !== null;
        var docs: PortalMortgageDocumentModel[];
        var applicants: ApplicableTo[]=[applicant];

        if (applicant === ApplicableTo.Primary && !secondaryApplicantExist) {
            applicants.push(ApplicableTo.Shared);
        }
        docs = this.props.documents.filter(i => applicants.includes(i.applicableTo as ApplicableTo) && i.category === category);
        if (context.mortgage?.approvalType === AIPType.HouseHunter && docs.filter(a => a.houseHunterDocument).length === 0) {
            return 'greyedcategory ';
        }
        return '';
    }

    getHouseHunterClassOpacity(applicant: ApplicableTo, category: string) {
        let context = this.getContext();
        let secondaryApplicantExist = this.props.applicants !== null && this.props.applicants !== undefined && this.props.applicants.length > 1 && this.props.applicants[1] !== null;
        var docs: PortalMortgageDocumentModel[];
        var applicants: ApplicableTo[]=[applicant];

        if (applicant === ApplicableTo.Primary && !secondaryApplicantExist) {
            applicants.push(ApplicableTo.Shared);
        }
        docs = this.props.documents.filter(i => applicants.includes(i.applicableTo as ApplicableTo) && i.category === category);
        if (context.mortgage?.approvalType === AIPType.HouseHunter && docs.filter(a => a.houseHunterDocument).length === 0) {
            return 'greyedpill ';
        }
        return '';
    }

    holdingPage() {
        return <Container fluid={true}>
            <div style={{ marginTop: -15, paddingBottom: 15, textAlign: 'center' }}>
            </div>
            <Row>
                <Col>
                    <h3 className="impact">We are still building your document journey.</h3>
                    <span className="subtext">As soon as it's ready you'll be able to upload your documents here</span>
                </Col>
            </Row>
            <Row className="pt-5">
                <Col>
                    <Icons.Spinner animation="border" role="status" />
                </Col>
            </Row>
        </Container>;

    }

    public render(): React.ReactNode {
        let allItems = this.props.items;
        let primaryItems = allItems.filter(i => i.mortgageDocument !== undefined && i.mortgageDocument!.applicableTo === ApplicableTo.Primary);
        let secondaryItems = allItems.filter(i => i.mortgageDocument !== undefined && i.mortgageDocument!.applicableTo === ApplicableTo.Secondary);
        let sharedItems = allItems.filter(i => i.mortgageDocument === undefined || i.mortgageDocument!.applicableTo === ApplicableTo.Shared);
        var documents = this.props.documents.filter(d => d.statusReason !== MortgageDocumentStatusCode.Inactive && d.displayToCustomer);
        
        var fullPercent = Math.round(this.calcPercent(documents));

        var primaryDocs = documents.filter(i => i.applicableTo === ApplicableTo.Primary && i.category !== "AIP")
            .sort((a, b) => {
                if (b.statusReason === MortgageDocumentStatusCode.Approved) return -1;
                if (a.statusReason === MortgageDocumentStatusCode.Approved) return 1;
                if (b.statusReason === MortgageDocumentStatusCode.Uploaded) return -1;
                if (a.statusReason === MortgageDocumentStatusCode.Uploaded) return 1;
                var acat = this.props.categories.find(c => c.category === a.category)?.order ?? 0;
                var bcat = this.props.categories.find(c => c.category === b.category)?.order ?? 0;
                return acat < bcat ? -1 : 1;
            });

        let secondaryApplicantExist = this.props.applicants !== null && this.props.applicants !== undefined && this.props.applicants.length > 1 && this.props.applicants[1] !== null;

        if(secondaryApplicantExist !== true) {
            primaryItems = allItems.filter(i => i.mortgageDocument !== undefined && (i.mortgageDocument!.applicableTo === ApplicableTo.Primary || i.mortgageDocument!.applicableTo === ApplicableTo.Shared));

            primaryDocs = documents.filter(i => i.applicableTo === ApplicableTo.Primary || i.applicableTo === ApplicableTo.Shared)
            .sort((a, b) => {
                if (b.statusReason === MortgageDocumentStatusCode.Approved) return -1;
                if (a.statusReason === MortgageDocumentStatusCode.Approved) return 1;
                if (b.statusReason === MortgageDocumentStatusCode.Uploaded) return -1;
                if (a.statusReason === MortgageDocumentStatusCode.Uploaded) return 1;
                var acat = this.props.categories.find(c => c.category === a.category)?.order ?? 0;
                var bcat = this.props.categories.find(c => c.category === b.category)?.order ?? 0;
                return acat < bcat ? -1 : 1;
            });
        }

        var secondaryDocs = documents.filter(i => i.applicableTo === ApplicableTo.Secondary).sort((a, b) => {
            if (b.statusReason === MortgageDocumentStatusCode.Approved) return -1;
            if (a.statusReason === MortgageDocumentStatusCode.Approved) return 1;
            if (b.statusReason === MortgageDocumentStatusCode.Uploaded) return -1;
            if (a.statusReason === MortgageDocumentStatusCode.Uploaded) return 1;
            var acat = this.props.categories.find(c => c.category === a.category)?.order ?? 0;
            var bcat = this.props.categories.find(c => c.category === b.category)?.order ?? 0;
            return acat < bcat ? -1 : 1;
        });
      
        var sharedDocs = !secondaryApplicantExist ? [] : documents.filter(i => i.applicableTo === ApplicableTo.Shared).sort((a, b) => {
            if (b.statusReason === MortgageDocumentStatusCode.Approved) return -1;
            if (a.statusReason === MortgageDocumentStatusCode.Approved) return 1;
            if (b.statusReason === MortgageDocumentStatusCode.Uploaded) return -1;
            if (a.statusReason === MortgageDocumentStatusCode.Uploaded) return 1;
            var acat:any = this.props.categories.find(c => c.category === a.category)?.order ?? 0;
            var bcat:any = this.props.categories.find(c => c.category === b.category)?.order ?? 0;
            return acat < bcat ? -1 : 1;
        });

        var inprogressColor = "#FE972B";
        var doneColor = "#40C999";
        const context = this.context as UserContextState;

        // Rudimentary way to split the categories into columns. Just mapping it and allowing it to
        // spill over to the next row causing expanding accordions to push down adjacent categories
        // If we do the below then the accordions are placed within their own vertical column
        let cols:any[][] = [[],[],[]];
        var firstCategory:string|null=null;
        if (!secondaryApplicantExist) {
            let col = 0;
            this.props.categories.filter(c => primaryDocs.filter(i => i.category === c.category).length > 0).forEach(c => {
                firstCategory = firstCategory ?? c.category;
                cols[col].push(c)
                col++;
                if (col > 2) col = 0; 
            });
        }
        var primaryCategories = this.props.categories.filter(c => primaryDocs.filter(i => i.category === c.category).length > 0);
        if (!primaryCategories || primaryCategories.length === 0) {
            return this.holdingPage();
        }
        var firstPrimaryCategory = context.mortgage?.approvalType === AIPType.HouseHunter && primaryCategories.length > 1 ? primaryCategories[1].category : primaryCategories[0].category;
        var firstSecondaryCategory = "";
        if (secondaryApplicantExist) {
            var secondaryCategories:any;
            secondaryCategories = this.props.categories.filter(c => secondaryDocs.filter(i => i.category === c.category).length > 0)
            if (!secondaryCategories || secondaryCategories.length === 0) {
                return this.holdingPage();
            }
            firstSecondaryCategory = secondaryCategories[0].category;
        }

        return (
            <Container>
                {context.mortgage?.approvalType === AIPType.HouseHunter ?
                <div style={{ marginTop: -15, paddingBottom: 15, textAlign: 'center' }}>
                    <span className="subtext">In order to assess your application for Approval in Principle we require the reduced number of <strong>documents highlighted below</strong>.<br/>
                    Once uploaded your mortgage advisor will review all promptly and come straight back to you, so let’s keep going!
                    </span>
                </div>
                :
                <div className="subtitle" style={{ textAlign: 'center' }}>
                    {fullPercent === 0 && <span className="subtext">Upload <span className='doddltext'>everything lenders need</span> for us to check & get you approved in principle</span>}
                    {fullPercent > 0 && fullPercent < 80 && <span className="subtext"><span className='inprogress'>{fullPercent}% Complete.</span> You're off to a great start {context.contact?.firstName}! Once you have completed all sections we will review all and be right back to you. Keep going!</span>}
                    {fullPercent >= 80 && fullPercent < 100 && <span className="subtext"><span className='inprogress'>{fullPercent}% Complete.</span> Almost there {context.contact?.firstName}, just a few documents left! Once you have completed all sections we will review all and be right back to you. Keep going!</span>}
                    {fullPercent >= 100 && <span className="subtext"><span className='doddltext'>You're finished!</span> Great work {context.contact?.firstName}, we will now review all and be right back to you!</span>}
                </div>
                }
                <Row>
                    
                    {/* IF IT'S SINGLE  */}
                    {!secondaryApplicantExist &&
                    <>
                        <h3 className="impact">{this.props.applicants![0]?.firstName}</h3>
                        {/* <Row> */}
                        {cols.map((categories, col) => (
                            <Col key={`catcol-${col}`} md={12} lg={4} style={{marginBottom: 20}}>

                            {categories.map((cat, idx) => (
                                (primaryDocs.filter(i => i.category === cat.category).length > 0 && 
                                    
                                // <Col sm={12} md={12} lg={!secondaryApplicantExist ? 4 : 12}>
                                    <Card className={`${this.getCardClass(ApplicableTo.Primary, cat.category)}`}>
                                        <Accordion defaultActiveKey={col===0 && context.contact?.contactId === context.mortgage?.primaryApplicant.contactId ? firstPrimaryCategory : ""}>
                                            <Accordion.Item  eventKey={cat.category} className={this.getHouseHunterClass(ApplicableTo.Primary, cat.category)}>
                                                <Accordion.Header>
                                                    <Row style={{width: '100%'}}>
                                                        <Col className="text-center pt-1">
                                                            <h4 className={`s ${this.getCardHeaderClass(ApplicableTo.Primary, cat.category)}`}>{cat.category}</h4>
                                                        </Col>
                                                    </Row>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <Stack gap={2} className="text-start">
                                                    {primaryDocs.filter(p => p.category === cat.category).map((item, idx2) => (
                                                        <DocumentPill
                                                            key={"pill-" + item.mortgageDocumentId}
                                                            status={item.statusReason}
                                                            category={item.category}
                                                            name={(item.description??'').trim() === '' ? item.name : item.description}
                                                            portalName={item.portalName}
                                                            helpText={item.helpText}
                                                            files={primaryItems.filter(f => f.mortgageDocument === item)}
                                                            contactId={this.props.applicants![0].contactId}
                                                            mortgageDocumentId={item.mortgageDocumentId}
                                                            onDone={this.onDocumentDone}
                                                            mortgageId={this.props.mortgageId}
                                                            documentType={item.documentType}
                                                            metFaceToFace={item.primaryApplicantMetFaceToFace}
                                                            comments={item.comments}
                                                            houseHunterDocument={item.houseHunterDocument}
                                                        />
                                                    ))}
                                                    </Stack>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </Card>
                                // </Col>
                                )
                            ))}
                            </Col>
                        ))}
                        </>
                    }


                    {/* IF IT'S JOINT  */}
                    {secondaryApplicantExist &&

                    <Col md={12} lg={4} style={{marginBottom: 20}}>

                        <h3 className="impact">{this.props.applicants![0]?.firstName}</h3>
                        {/* <Row> */}
                        {this.props.categories.map((cat, idx) => (
                            (primaryDocs.filter(i => i.category === cat.category).length > 0 &&
                            // <Col sm={12} md={12} lg={!secondaryApplicantExist ? 4 : 12}>
                                <Card key={`primarycard-${idx}`} className={this.getCardClass(ApplicableTo.Primary, cat.category)}>
                                    <Accordion defaultActiveKey={context.contact?.contactId === context.mortgage?.primaryApplicant.contactId ? firstPrimaryCategory : ""}>
                                        <Accordion.Item  eventKey={cat.category} className={this.getHouseHunterClass(ApplicableTo.Primary, cat.category)}>
                                            <Accordion.Header>
                                                <Row style={{width: '100%'}}>
                                                    <Col className="text-center pt-1">
                                                        <h4 className={`s ${this.getCardHeaderClass(ApplicableTo.Primary, cat.category)}`}>{cat.category}</h4>
                                                    </Col>
                                                </Row>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <Stack gap={2} className="text-start">
                                                {primaryDocs.filter(p => p.category === cat.category).map((item, idx2) => (
                                                    <DocumentPill
                                                        key={"pill-" + item.mortgageDocumentId}
                                                        status={item.statusReason}
                                                        category={item.category}
                                                        name={(item.description??'').trim() === '' ? item.name : item.description}
                                                        portalName={item.portalName}
                                                        helpText={item.helpText}
                                                        files={primaryItems.filter(f => f.mortgageDocument === item)}
                                                        contactId={this.props.applicants![0].contactId}
                                                        mortgageDocumentId={item.mortgageDocumentId}
                                                        onDone={this.onDocumentDone}
                                                        mortgageId={this.props.mortgageId}
                                                        documentType={item.documentType}
                                                        metFaceToFace={item.primaryApplicantMetFaceToFace}
                                                        comments={item.comments}
                                                        houseHunterDocument={item.houseHunterDocument}
                                                    />
                                                ))}
                                                </Stack>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </Card>
                            // </Col>
                            )
                        ))}
                        {/* </Row> */}
                    </Col>
                    }

                    {secondaryApplicantExist &&
                        <>
                            <Col md={12} lg={4} style={{marginBottom: 20}}>
                                <h3 className="impact">{this.props.applicants![1]?.firstName}</h3>
                                {this.props.categories.map((cat, idx) => (
                                    (secondaryDocs.filter(i => i.category === cat.category).length > 0 &&
                                        <Card key={`secondarycard-${idx}`} className={this.getCardClass(ApplicableTo.Secondary, cat.category)}>
                                            <Accordion defaultActiveKey={context.contact?.contactId === context.mortgage?.secondaryApplicant.contactId ? firstSecondaryCategory : ""}>
                                                <Accordion.Item  eventKey={cat.category} className={this.getHouseHunterClass(ApplicableTo.Primary, cat.category)}>
                                                    <Accordion.Header>
                                                        <Row style={{width: '100%'}}>
                                                            <Col className="text-center pt-1">
                                                                <h4 className={`s ${this.getCardHeaderClass(ApplicableTo.Secondary, cat.category)}`}>{cat.category}</h4>
                                                            </Col>
                                                        </Row> 
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <Stack gap={2} className="text-start">
                                                        {secondaryDocs.filter(p => p.category === cat.category).map((item, idx2) => (
                                                            <DocumentPill
                                                                key={"pill-" + item.mortgageDocumentId}
                                                                status={item.statusReason}
                                                                category={item.category}
                                                                name={(item.description??'').trim() === '' ? item.name : item.description}
                                                                portalName={item.portalName}
                                                                helpText={item.helpText}
                                                                files={secondaryItems.filter(f => f.mortgageDocument === item)}
                                                                contactId={this.props.applicants![1].contactId}
                                                                mortgageDocumentId={item.mortgageDocumentId}
                                                                onDone={this.onDocumentDone}
                                                                mortgageId={this.props.mortgageId}
                                                                documentType={item.documentType}
                                                                metFaceToFace={item.secondaryApplicantMetFaceToFace}
                                                                comments={item.comments}
                                                                houseHunterDocument={item.houseHunterDocument}
                                                            />
                                                        ))}
                                                        </Stack>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </Card>
                                    )
                                ))}
                            </Col>
                            <Col md={12} lg={4}>
                                <h3 className="impact">Shared</h3>
                                {this.props.categories.map((cat, idx) => (
                                    (sharedDocs.filter(i => i.category === cat.category).length > 0 &&
                                        <Card key={`sharedcard-${idx}`} className={this.getCardClass(ApplicableTo.Shared, cat.category)}>
                                            <Accordion>
                                                <Accordion.Header>
                                                    <Row style={{width: '100%'}}>
                                                        <Col className="text-center pt-1">
                                                            <h4 className={`s ${this.getCardHeaderClass(ApplicableTo.Shared, cat.category)}`}>{cat.category}</h4>
                                                        </Col>
                                                    </Row>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <Stack gap={2} className="text-start">
                                                    {sharedDocs.filter(p => p.category === cat.category).map((item, idx2) => (
                                                        <DocumentPill
                                                            key={"pill-" + item.mortgageDocumentId}
                                                            status={item.statusReason}
                                                            category={item.category}
                                                            name={(item.description??'').trim() === '' ? item.name : item.description}
                                                            portalName={item.portalName}
                                                            helpText={item.helpText}
                                                            files={sharedItems.filter(f => f.mortgageDocument === item)}
                                                            contactId={this.props.applicants![0].contactId}
                                                            mortgageDocumentId={item.mortgageDocumentId}
                                                            onDone={this.onDocumentDone}
                                                            mortgageId={this.props.mortgageId}
                                                            documentType={item.documentType}
                                                            metFaceToFace={item.primaryApplicantMetFaceToFace}
                                                            comments={item.comments}
                                                            houseHunterDocument={item.houseHunterDocument}
                                                        />
                                                    ))}
                                                    </Stack>
                                                </Accordion.Body>
                                            </Accordion>
                                        </Card>
                                    )
                                ))}
                            </Col>
                        </>}
                </Row>
                <br />
                <br />
            </Container>
        );
    }

}